<template>
  <div class="layout" :style="rootStyles">
    <NuxtLoadingIndicator class="layout__indicator" :height="1.5" />
    <NewHeaderMainController />
    <FeedControllerSkeleton />
    <main class="layout__main">
      <div class="layout__main_gradient" />
      <div class="layout__main_page">
        <slot />
      </div>
    </main>
    <StatisticControllerSkeleton>
      <StatisticController
        :keys-show="[
          EStatisticItemVariants.OPENED,
          EStatisticItemVariants.USERS,
          EStatisticItemVariants.UPGRADES,
          EStatisticItemVariants.BATTLES,
          EStatisticItemVariants.ONLINE,
          EStatisticItemVariants.ARCANES,
        ]"
      />
    </StatisticControllerSkeleton>

    <FooterControllerSkeleton />
  </div>
</template>

<script setup lang="ts">
import { useBattlesDataStateStore } from '~/features/battles/store/dataState.store';
import { useUserStore } from '~/store/user/user.store';
import { useBattlesListStore } from '~/features/battles/store/battlesList.store';
import { usePlayersStore } from '~/features/battles/store/players.store';
import { useSingleBattleStore } from '~/features/battles/store/singleBattle.store';
import { EStatisticItemVariants } from '~/config/apps/index.types';

import { EBattlesSocketEvents } from '~/features/battles/constants/general';
import type { IBattleUpdateSocket } from '~/features/battles/types/webSocketData';
import type {
  IBattlesBattleFullInfoEntity,
  IBattlesEmodjiEntity,
  IBattlesShortInfoBattleEntity,
} from '~/repository/modules/battles';
import { useSocketsStore } from '~/store/sockets/sockets.store';

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const img = useImage();

const battlesDataStateStore = useBattlesDataStateStore();

const battlesListStore = useBattlesListStore();
const playersStore = usePlayersStore();
const singleBattleStore = useSingleBattleStore();

const socketsStore = useSocketsStore();

const { MAIN, HISTORY, STATISTICS, BATTLE } = ROUTING.BATTLES;

const rootStyles = computed(() => {
  const leftUrl = changeXL('/static/battles/layout-bg-left.png', '/static/battles/layout-bg-left-mobile.png');
  const rightUrl = changeXL('/static/battles/layout-bg-right.png', '/static/battles/layout-bg-right-mobile.png');

  return {
    '--bg-image-left': `url('${img(leftUrl, { format: 'webp', quality: 100 })}')`,
    '--bg-image-right': `url('${img(rightUrl, { format: 'webp', quality: 100 })}')`,
  };
});

/* Если есть юзер, делаем initial-запрос на статус юзера */
watch(
  () => useUserStore().userId,
  async (userId) => {
    if (userId) await battlesDataStateStore.fetchUserStatus();
  },
);

function handleBattlesListener(data: { battle: IBattlesShortInfoBattleEntity }) {
  battlesListStore.handleBattlesListener(data);
}

function handleBattleChanging(data: { battle: IBattlesBattleFullInfoEntity }) {
  playersStore.handleBattleChanging(data);
}

function handleEmojiListener(data: { emoji: IBattlesEmodjiEntity }) {
  playersStore.handleEmojiListener(data);
}

function handleBattleListener(data: IBattleUpdateSocket) {
  singleBattleStore.handleBattleListener(data);
}

onBeforeMount(() => {
  socketsStore.addHandler([BATTLE.name], EBattlesSocketEvents.BATTLE_CHANGED, (data: never) => {
    handleBattleChanging(data);
    handleBattleListener(data);
  });
  socketsStore.addHandler([BATTLE.name], EBattlesSocketEvents.EMOJI_RECEIVED, handleEmojiListener);
  socketsStore.addHandler(
    [MAIN.name, HISTORY.name, STATISTICS.name],
    EBattlesSocketEvents.BATTLE_CHANGED,
    handleBattlesListener,
  );
});

onMounted(() => {
  battlesDataStateStore.changeDataState('battlesRendered', true);
});
</script>

<style lang="scss" scoped src="assets/layouts/battles.scss"></style>
